/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/animejs@3.2.1/lib/anime.min.js
 * - /npm/enquire.js@2.1.6/dist/enquire.min.js
 * - /npm/waypoints@4.0.1/lib/noframework.waypoints.min.js
 * - /npm/waypoints@4.0.1/lib/shortcuts/inview.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
